<template>
<div>

  <loader v-if="loadingDeals"></loader>
    <div class=prlngt v-if="!loadingDeals  && activeDealData('DealInfo').CanLong === 1 && activeDealData('DealInfo').Is_pdl === 1 &&
         activeDealData('DealInfo').StateDeal === 0">
    <div class='flex'>
  <div class="layoutBorder cd1">
     <div class='mainTxt'>
     <p>В жизни наших клиентов бывают непредвиденные ситуации и не всегда вовремя удается осуществить платеж по договору. <br></p>
     <p> Именно поэтому мы предлагаем не беспокоиться о штрафах за просроченную задолженность с услугой <span
       class='prolongationTxt'>"продление договора"</span></p>
<!--              <div v-if="parseInt(activeDealData('DealInfo').SummaLong, 10) === 0">-->
<!--                <p>Оплатите сумму в  <span class='sumTxt'-->
<!--                                           :inner-html.prop="activeDealData('DealInfo').SummaLong | moneyFormat"></span> и мы-->
<!--                  продлим договор на {{activeDealData('DealInfo').DealDayCount}} дней.</p>-->
<!--              </div>-->
<!--              <div v-else>-->
              <div>
                <p>Оплатите сумму в
                  <span  class='sumTxt' :inner-html.prop="activeDealData('DealInfo').SumForLong | moneyFormat"></span><br/>
                  (<span :inner-html.prop="serviceSumm | moneyFormat"></span> – стоимость услуги,<br/>
                  <span :inner-html.prop="activeDealData('DealInfo').Penny | moneyFormat"></span> – начисленные пени),
                  и мы продлим договор на {{activeDealData('DealInfo').DealDayCount}} дней.</p>
              </div>
     </div>
  </div>
  <div class="layoutBorder cd2">
      <div class='mainTxt banner'>
          <img class="imgman" src="@/assets/man.png">
          <div class='backgrnd'>
     <p>"Что бы штраф не получить<br>
      Лучше договор продлить"</p>
      </div>
     </div>
  </div>
  </div>
  <div class='flex'>
    <div class="layoutBorder cd3">
     <div class='mainTxt'>
      <p>Договор продлится сроком на <span class='termTxt'>{{activeDealData('DealInfo').DealDayCount}} дней</span></p>
      <div class ='dateSection'>
          <!-- вычисляемая дата: -->
          <p>{{activeDealData('DealInfo').NewDateLong | timeFromXtoDateView}}</p>
          <p class='dateSectionTxt'>Новая дата платежа</p>
      </div>
       <img :src="pixel.loaded + activeDealData('DealInfo').DlCode" alt="" class="pixel">
      <button v-on:click.prevent="redirectToPayment"> Продлить договор </button>
<!--      <p class='hreff'><a>Условия предоставления услуги</a></p>-->
      </div>
  </div>
</div>
  </div>
  </div>
</template>

<script>
  import {mapGetters,mapState} from 'vuex';
 export default {
    name: "prolongation",
    data(){
     return {
       pixel:{
         loaded:'https://lk.msk.finmoll.com/api/pixel.php?pixel_id=6&demand=',
         click:''
       }
     }
    },
     computed: {
       ...mapState({
         loadingDeals:state=>state.contentData.deals.isLoading
       }),
     ...mapGetters([
       'activeDealData',
     ]),
       serviceSumm() {
         if (!this.loadingDeals && this.activeDealData('DealInfo').SumForLong != undefined &&
         this.activeDealData('DealInfo').Penny != undefined) {
           return parseInt(this.activeDealData('DealInfo').SumForLong, 10) -
             parseInt(this.activeDealData('DealInfo').Penny, 10);
         } else {
           return 0;
         }
       }
   },
   methods:{
     returnPdlStatus(){
        return new Promise((resolve, reject) => {
          this.$myApi.singleRequest({
            action:'returnPdlStatus',
            type:'pdl',
            demand:parseInt(this.activeDealData('DealInfo').DlCode, 10)
          })
          .then(res=>{console.log(res);resolve(res)})
          .catch(res=>{console.log(res);reject()})
        });
      },
     redirectToPayment(){
      this.returnPdlStatus()
        .then((res)=>{
          // тут бы замутить со статусами лонгации
            this.$external.universalRequest({
              action:'pdl_SetRenewalFlag',
              externalType: 'zita',
              demid: parseInt(this.activeDealData('DealInfo').DlCode, 10),
            }).then((res)=>{
              if (this.activeDealData('DealInfo').SumForLong == 0) {
                alert('Заявка оформлена, ожидайте');
                this.$router.push('/');
              } else {
                localStorage.setItem('longationActive','true');
                let time = new Date();
                localStorage.setItem('longationActiveTime',time.getTime());
                this.$router.push('/payment/longation');
              }
            }).catch((res)=>{
              if ( res.errorCode > 0 ) {
                alert( res.errorMsg)
              } else {
                alert('Возникла ошибка, попробуйте позже');
              }
            });
        }).catch(()=>{

      });
     }
   },



  }
</script>

<style scoped>

.prolongationTxt{font-weight: 500; color:#09b852}
.termTxt{font-weight: 500;}
.sumTxt{position: relative; font-size: 6vw;top: 0.7vw;}
button{display: block;margin: auto;}
.cd3 > div > p {text-align: center;}
.cd3 > div > div > p {text-align: center;}
a{font-size: 3.2vw; text-decoration: underline; color:rgb(6, 176, 255)}
.flex {display: flex; flex-direction: column;}
.scndFlx{ display:flex; flex-direction: column;}
.mainTxt{     height: 70vw; border-bottom: 2px solid #DFE0E1;border-top: 2px solid #DFE0E1;  padding: 6vw 3vw 6vw 3vw;margin-bottom: 8vw;margin-top: 8vw;}
.dateSection{width: 39vw; padding: 4vw;margin: auto;margin-bottom: 4vw;border: 2px solid rgba(0, 15, 56, 0.21);border-radius: 6px;}
.dateSectionTxt{color: rgba(0, 15, 56, 0.50);    font-size: 3.7vw;}
.cd3 > div > div > p {margin-bottom: 1vw;}
.backgrnd{position: absolute;background-color: rgb(238 240 247);width: 77vw;top: 3vw;left: 15vw;height: 29vw;border-radius: 12vw;}
.backgrnd > p {    position: absolute;top: 50%;left: 50%;margin-right: -50%;transform: translate(-50%, -50%);font-weight:500}
.banner{display: flex; position: relative;}
.imgman{position:relative;width: 41vw;height: 49vw;top: 27.1vw;}

@media screen and (min-width: 760px){
.mainTxt{height: 30vw; padding: 2vw;margin-bottom: 3vw; margin-top: 3vw;}
.sumTxt{font-size: 30px;font-size: 3.3vw;top: 0.3vw;}
.layoutHeader{padding-bottom:35px}
.flex{flex-direction: row;}
.href{padding-top: 5px;}
.layoutBorder{width: 47%; margin:10px;}
.dateSection{padding: 2vw;width: 16vw;margin-bottom: 3vw;margin-top: 3vw;}
.dateSectionTxt{font-size: 1.5vw;}
.scndFlx{ justify-content: flex-start;}
.prlngt{width: 99%; margin: auto;}
.cd1 > div > p {margin-bottom: 3vw;}
a{font-size: 1.2vw;}
.backgrnd{width: 32vw;top: 1vw;left: 11vw;height: 12vw;border-radius: 6vw;}
.imgman{width: 19vw;height: 23vw;top: 9.1vw;}
}

@media screen and (min-width: 1200px){
.backgrnd{width: 326px;left: 293px;height: 133px;top:17px;border-radius: 62px;}
.mainTxt{ height: 230px; padding: 37px 29px 19px 29px; margin-bottom:30px; margin-top:30px}
.sumTxt{font-size: 30px;    font-size: 30px;top: 6px;}
.layoutHeader{padding-bottom:35px}
.flex{flex-direction: row;}
.href{padding-top: 5px;}
.layoutBorder{width: 47%; margin:10px;}
.dateSection{padding: 15px;width: 125px; margin-bottom: 35px;margin-top: 15px;}
.dateSectionTxt{    font-size: 12px;}
.scndFlx{ justify-content: flex-start;}
.prlngt{width: 1366px;margin: auto;}
.cd1 > div > p {margin-bottom: 30px;}
a{font-size: 12px;}
.cd3 > div > div > p {margin-bottom: 0vw;}
.imgman{width: 217px;height: 263px;top: -13.5px;}
}
</style>
